.App {
  background-image: url('./assets/Background.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .handContainer {
    background-image: url('./assets/Dust-overlay.png');
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    .hand {
      width: 125%;
      // height: 100vh;
    }
  }

  .scanner {
    width: 100%;
    position: absolute;
    top: -10%;
    animation-name: scanning;
    webkit-animation-name: scanning;
    animation-duration: 2s;
    webkit-animation-duration: 1s;
    animation-direction: alternate-reverse;
    webkit-animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    webkit-animation-iteration-count: infinite;
    // transition: 2s;
  }

  .incentive {
    position: absolute;
    font-size: 5rem;
    bottom: 1rem;
    margin: 0;
    font-family: INGMe-bold;
    color: white;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e65c00,
      0 0 40px #e65c00, 0 0 50px #e65c00, 0 0 60px #e65c00, 0 0 70px #e65c00;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff8b4d, 0 0 40px #ff8b4d,
      0 0 50px #ff8b4d, 0 0 60px #ff8b4d, 0 0 70px #ff8b4d, 0 0 80px #ff8b4d;
  }
}

@keyframes scanning {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(100vh));
  }
}

.modalStyle {
  -webkitboxshadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  padding: 2rem;
  border-radius: 1.25rem;

  &:before {
    content: '';
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #00ffd5,
      #48ff00,
      #fffb00,
      #ff7300,
      #ff0000
    );
    position: absolute;
    top: -8px;
    left: -8px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 15px);
    height: calc(100% + 15px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    left: 0;
    top: 0;
    border-radius: 10px;
  }
}

.modalContent {
  color: white;
  font-family: INGMe-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 10rem;
  }
  .title {
    font-size: 4rem;
    text-align: center;
    margin: 1rem 0;
  }

  .content {
    display: flex;
    // align-items: center;

    img {
      //   width: 6rem;
      height: 100%;
    }
  }

  .modalActions {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: flex-end;

    div {
      //   border: 1px solid white;
      //   background-color: #e65c00;
      //   font-weight: bold;
      //   border-radius: 1.25rem;
      //   padding: 1rem;
      //   height: fit-content;
      margin-right: 1rem;
      &:last-of-type {
        margin: 0;
      }

      p {
        margin: 0;
        font-size: 1.5rem;
      }

      &.yes {
        height: 4rem;
        p {
          font-size: 2.5rem;
        }
      }
    }
  }

  .text {
    padding: 1rem 0;
    font-size: 2rem;
  }
}

.animated-button {
  /* CSS */
  .button-73 {
    appearance: none;
    background-color: #ffffff;
    border-radius: 40em;
    border-style: none;
    box-shadow: #adcfff 0 -12px 6px inset;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: -0.24px;
    margin: 0;
    outline: none;
    padding: 1rem 1.3rem;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-73:hover {
    background-color: #ffc229;
    box-shadow: #ff6314 0 -6px 8px inset;
    transform: scale(1.125);
  }

  .button-73:active {
    transform: scale(1.025);
  }

  @media (min-width: 768px) {
    .button-73 {
      font-size: 1.5rem;
      padding: 0.75rem 2rem;
    }
  }
}

.animated-button-no {
  /* CSS */
  .button-73 {
    appearance: none;
    background-color: #ffffff;
    border-radius: 40em;
    border-style: none;
    box-shadow: #adcfff 0 -12px 6px inset;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: -0.24px;
    margin: 0;
    outline: none;
    padding: 1rem 1.3rem;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-73:hover {
    background-color: #297fff;
    box-shadow: #1437ff 0 -6px 8px inset;
    transform: scale(1.125);
  }

  .button-73:active {
    transform: scale(1.025);
  }

  @media (min-width: 768px) {
    .button-73 {
      font-size: 1.5rem;
      padding: 0.75rem 2rem;
    }
  }
}
